import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import './index.scss';
import 'animate.css/animate.min.css';

// Layouts
import Layout from './app';

// Pages
import Home from './components/home';
import PageNotFound from './components/404';
import ContactPage from './components/pages/contact/contact';
import TarifsPage from './components/pages/tarification/tarification';
import LegalNoticePage from './components/pages/legal-notice/legal-notice';
import EquipementsPage from './components/pages/equipements/equipements';
import DecouvrirPage from './components/pages/decouvrir/decouvrir';

class Root extends React.Component {
  render() {
    return (
      <GoogleReCaptchaProvider reCaptchaKey="6LfoJ-8pAAAAAIMCC0Twgwc5f3VwIMcGRHwCZVOY">
        <BrowserRouter basename={'/'}>
          <ScrollContext>
            <Layout>
              <Switch>
                {/* Home */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/`}
                  component={Home}
                />
                {/* Tarifs et réservation */}
                <Route
                  path={`${process.env.PUBLIC_URL}/tarifs`}
                  component={TarifsPage}
                />
                {/* Équipements */}
                <Route
                  path={`${process.env.PUBLIC_URL}/equipements`}
                  component={EquipementsPage}
                />
                {/* À découvrir */}
                <Route
                  path={`${process.env.PUBLIC_URL}/decouvrir`}
                  component={DecouvrirPage}
                />
                {/* Mentions légales */}
                <Route
                  path={`${process.env.PUBLIC_URL}/mentions-legales`}
                  component={LegalNoticePage}
                />
                {/* Contactez-nous */}
                <Route
                  path={`${process.env.PUBLIC_URL}/contact`}
                  component={ContactPage}
                />

                {/* Default 404 route */}
                <Route component={PageNotFound} />
              </Switch>
            </Layout>
          </ScrollContext>
        </BrowserRouter>
      </GoogleReCaptchaProvider>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById('root'));
