import React, { Component } from 'react';
import './caltoaction.scss';
import Modal from 'react-responsive-modal';
import BookingOpenPro from '../../common/booking/booking';

class CallToActionSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  onOpenModal = (e) => {
    e.preventDefault();
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div className="call-to-action text-center">
        <div className="container">
          <h2 className="text-uppercase text-white">CONSULTEZ NOS</h2>
          <h1 className="text-uppercase text-white mb-4">DISPONIBILITÉS !</h1>
          <div className="btn-call-to-action">
            <button
              type="button"
              className="btn btn-dark"
              onClick={this.onOpenModal}
            >
              VÉRIFIER MAINTENANT
            </button>
          </div>
        </div>
        <Modal open={this.state.open} onClose={this.onCloseModal} center>
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content quick-view-modal">
              <div className="modal-body">
                <BookingOpenPro
                  source={`Page d'accueil -> Call To Action -> Modale de réservation`}
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default CallToActionSection;
