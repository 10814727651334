import React, { Component } from 'react';
import Slider from 'react-slick';
import './slider.scss';

class CarouselPage1 extends Component {
  constructor(props) {
    super(props);
    this.data = [
      {
        image: `/assets/images/slider/exterieur-terrasse-885x600.jpg`,
        title: `ENTREE JARDIN`,
        description:
          "L'entrée du jardin du Gîte de Gaïa vous accueille dans un cadre verdoyant et paisible, promesse d'un séjour ressourçant en pleine nature. Découvrez un espace extérieur soigneusement aménagé où la biodiversité s'épanouit.",
        link: '#',
        alt: 'Gîte de Gaïa - Extérieur',
      },
      {
        image: `/assets/images/slider/terrasse-900x600.jpg`,
        title: `TERRASSE JARDIN`,
        description:
          "La terrasse exposée à l'est, permet de profiter du soleil le matin au petit déjeuner et de se détendre dans un transat à l'ombre l'après midi. Un espace convivial...",
        link: '#',
        alt: 'Gîte de Gaïa - Terrasse',
      },
    ];

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);

    this.state = {
      active: this.data[0],
    };
  }

  next() {
    this.slider.slickNext();
  }

  previous() {
    this.slider.slickPrev();
  }

  slideChange = (old, index) => {
    this.setState({
      active: this.data[index],
    });
  };

  render() {
    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <div className={'carousel1-wrap position-relative'}>
        <Slider
          ref={(slide) => (this.slider = slide)}
          {...settings}
          beforeChange={this.slideChange}
        >
          {this.data.map((data, index) => (
            <div className="slider-item" key={index}>
              <img src={data.image} alt={data.alt} />
            </div>
          ))}
        </Slider>
        <div className="slider-content d-flex justify-content-end align-items-end flex-wrap">
          <div className="navigation">
            <button className="arrow-button arrow-prev" onClick={this.previous}>
              Previous
            </button>
            <button className="arrow-button arrow-next" onClick={this.next}>
              Next
            </button>
          </div>
          <div className="slider-contentn">
            <div className="slider-info mt-auto">
              <h4 className="text-white text-uppercase mb-4">
                {this.state.active.title}
              </h4>
              <p>{this.state.active.description}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CarouselPage1;
