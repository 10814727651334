import React, { Component } from 'react';
import './availability.scss';
import Heading from '../../common/heading/heading';
import _ from 'lodash';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import LoadingBlock from '../../common/loading/loading';

class AvailabilitySection extends Component {
  data = [];
  keys = [];
  state = {
    photoIndex: 0,
    isOpen: false,
    isLoading: true,
  };

  constructor(props) {
    super(props);
    this.load();
  }

  load = () => {
    fetch('/api/availability.json')
      .then((response) => response.json())
      .then((res) => {
        this.data = res;
        this.setState({ isLoading: false });
      });
  };

  getHeading = () => {
    this.keys = _.keys(this.data[0]);
    return this.keys;
  };

  render() {
    const { photoIndex, isOpen } = this.state;
    return (
      <div className="container">
        <Heading>TARIFICATION</Heading>

        {!this.state.isLoading ? (
          <>
            <div className="block-table table-responsive">
              <table className="table">
                <thead>
                  <tr className="property-variation-item text-uppercase">
                    {this.getHeading().map((heading, index) => (
                      <th key={index}>{heading}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {this.data.map((item, index) => (
                    <tr className="property-variation-item" key={index}>
                      {this.keys.map((key, i) =>
                        key === 'floor plan' ? (
                          <td key={i}>
                            <a
                              href={null}
                              onClick={() => this.setState({ isOpen: true })}
                              className={`button-primary`}
                            >
                              View Now
                            </a>
                          </td>
                        ) : (
                          <td key={i}>{item[key]}</td>
                        )
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div class="container mt-5">
              <div class="row">
                <div class="col-10 mx-auto">
                  <p
                    className="text-center text-uppercase"
                    style={{ marginTop: '20px', fontWeight: 'bold' }}
                  >
                    TAXE DE SÉJOUR
                  </p>
                  <p className="text-center" style={{ marginTop: '20px' }}>
                    Pour chaque nuit passée dans notre établissement, une taxe
                    de séjour est applicable aux adultes et aux enfants de 13
                    ans et plus. Cette taxe est fixée par la municipalité et est
                    destinée à contribuer aux dépenses liées à l’accueil des
                    touristes et à l’amélioration des infrastructures locales.
                    Le montant de la taxe est de 1,40 € par personne et par
                    nuit. Il est à régler sur place à la fin de votre séjour.
                    <br />
                    <br />
                    <span style={{ fontStyle: 'italic' }}>
                      Veuillez noter : La taxe de séjour est gratuite pour les
                      enfants de moins de 13 ans.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            {isOpen && (
              <Lightbox
                mainSrc={this.data[photoIndex]['floor plan']}
                onCloseRequest={() => this.setState({ isOpen: false })}
              />
            )}
          </>
        ) : (
          <LoadingBlock />
        )}
      </div>
    );
  }
}

export default AvailabilitySection;
