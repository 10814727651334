import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './breadcrumb.scss';

class Breadcrumb extends Component {
  renderParent(items) {
    return items.map((item, index) => (
      <li className="breadcrumb-item" key={index}>
        <Link to={`${process.env.PUBLIC_URL}/${item.path}`}>{item.title}</Link>
      </li>
    ));
  }

  render() {
    const { title, parent } = this.props;

    return (
      <div
        className="breadcrumb-section"
        style={{ backgroundColor: '#3f4448' }}
      >
        <div className="container">
          <div className="page-title">
            <h2>{title}</h2>
          </div>
          <nav className="theme-breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={`${process.env.PUBLIC_URL}`}>Accueil</Link>
              </li>
              {parent ? this.renderParent(parent) : ''}
              <li className="breadcrumb-item active">{title}</li>
            </ol>
          </nav>
        </div>
      </div>
    );
  }
}

export default Breadcrumb;
