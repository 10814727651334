import React, { Component } from 'react';
import Slider from 'react-slick';
import './slider.scss';
class SliderHome extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      rows: 3,
    };

    return (
      <div className={'slider-wrap position-relative'}>
        <Slider {...settings}>
          <div className="slider-item position-relative">
            <div
              className="slider-image"
              style={{
                backgroundImage:
                  'url(/assets/images/slider/facade-jardin-2592x1944.jpg)',
              }}
            ></div>

            <div
              className="slider-overlay"
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: 'rgba(0,0,0,0.5)',
              }}
            ></div>

            <div className="container">
              <div className="slider-content">
                <div className="text-center w-100">
                  <h2 className="text-white">
                    <i
                      class="fa-regular fa-star"
                      style={{ color: 'white', fontSize: '30px' }}
                    ></i>
                    <i
                      class="fa-regular fa-star"
                      style={{ color: 'white', fontSize: '30px' }}
                    ></i>
                    <i
                      class="fa-regular fa-star"
                      style={{ color: 'white', fontSize: '30px' }}
                    ></i>
                    <br />
                    Échappée Verte dans
                    <br />
                    le Gîte de Gaïa
                  </h2>
                  <h5 className="text-white mt-5">
                    Un Havre Écologique – Confort et Nature
                    <br />
                    Pour 4 Personnes.
                  </h5>
                </div>
                <div className="slider-footer text-left">
                  <h5 className="text-white">
                    À la Découverte de Rocheservière
                  </h5>
                  <p className="text-white">
                    Profitez de la campagne, loisirs et culture
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}

export default SliderHome;
