import React, { Component } from 'react';

class Copyright extends Component {
  render() {
    const currentYear = new Date().getFullYear();

    return (
      <div className="copyright">
        <p>
          Copyright © {currentYear} {this.props.title}. Tous droits réservés.
          <br />
          Fait avec <i className="fa fa-heart" /> par{' '}
          <a href="https://vedus.io">Vedus</a>
        </p>
      </div>
    );
  }
}

export default Copyright;
