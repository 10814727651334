import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Heading from '../../common/heading/heading';

const images = [
  '/assets/images/photos/gite-de-gaia-1-1350x900.jpg',
  '/assets/images/photos/gite-de-gaia-2-1350x900.jpg',
  '/assets/images/photos/gite-de-gaia-3-1350x900.jpg',
  '/assets/images/photos/gite-de-gaia-4-600x900.jpg',
  '/assets/images/photos/gite-de-gaia-5-675x900.jpg',
  '/assets/images/photos/gite-de-gaia-6-675x900.jpg',
  '/assets/images/photos/gite-de-gaia-7-675x900.jpg',
  '/assets/images/photos/gite-de-gaia-8-1200x900.jpg',
  '/assets/images/photos/gite-de-gaia-9-675x900.jpg',
  '/assets/images/photos/gite-de-gaia-10-1200x900.jpg',
  '/assets/images/photos/gite-de-gaia-11-1200x900.jpg',
  '/assets/images/photos/gite-de-gaia-12-1350x900.jpg',
  '/assets/images/photos/gite-de-gaia-13-1200x900.jpg',
  '/assets/images/photos/gite-de-gaia-14-1200x900.jpg',
  '/assets/images/photos/gite-de-gaia-15-1600x900.jpg',
  '/assets/images/photos/gite-de-gaia-16-1350x900.jpg',
  '/assets/images/photos/gite-de-gaia-17-1350x900.jpg',
  '/assets/images/photos/gite-de-gaia-18-1350x900.jpg',
  '/assets/images/photos/gite-de-gaia-19-1350x900.jpg',
  '/assets/images/photos/gite-de-gaia-20-1350x900.jpg',
  '/assets/images/photos/gite-de-gaia-21-1350x900.jpg',
  '/assets/images/photos/gite-de-gaia-22-1350x900.jpg',
  '/assets/images/photos/gite-de-gaia-23-600x900.jpg',
  '/assets/images/photos/gite-de-gaia-24-675x900.jpg',
  '/assets/images/photos/gite-de-gaia-25-1600x900.jpg',
  '/assets/images/photos/gite-de-gaia-26-1348x900.jpg',
  '/assets/images/photos/gite-de-gaia-27-506x900.jpg',
  '/assets/images/photos/gite-de-gaia-28-1350x900.jpg',
  '/assets/images/photos/gite-de-gaia-29-1350x900.jpg',
  '/assets/images/photos/gite-de-gaia-30-1350x900.jpg',
  '/assets/images/photos/gite-de-gaia-31-1350x900.jpg',
  '/assets/images/photos/gite-de-gaia-32-600x900.jpg',
  '/assets/images/photos/gite-de-gaia-33-1350x900.jpg',
];

class GallerrySection extends Component {
  state = {
    isOpen: false,
    currentImage: '',
  };

  open = (e, image) => {
    e.preventDefault();
    this.setState({ isOpen: true, currentImage: image });
  };

  renderImages() {
    const columns = [[], [], [], []]; // Initialiser quatre colonnes vides
    images.forEach((image, index) => {
      columns[index % 4].push(
        <div key={image} className="gallerry-image">
          <a href="#" onClick={(e) => this.open(e, image)}>
            <img
              className="img-fluid"
              src={image}
              alt={`Gallery image ${index + 1}`}
            />
            <div className="gallery-item-overlay">
              <i className="opal-icon-zoom"></i>
            </div>
          </a>
        </div>
      );
    });
    return columns.map((col, index) => (
      <div key={index} className="col-lg-3 col-md-6 col-md-12">
        {col}
      </div>
    ));
  }

  render() {
    const { flexRow } = this.props;
    return (
      <>
        <Heading>Gallerie</Heading>
        <div className="gallerry-wrap p-0">
          <div className={`row${flexRow ? ' flex-row-reverse' : ''}`}>
            {this.renderImages()}
          </div>
        </div>
        {this.state.isOpen && (
          <Lightbox
            mainSrc={this.state.currentImage}
            onCloseRequest={() => this.setState({ isOpen: false })}
          />
        )}
      </>
    );
  }
}

export default GallerrySection;
