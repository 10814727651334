import React from 'react';
import Breadcrumb from '../../common/breadcrumb/breadcrumb';
import Helmet from 'react-helmet';
import './contact.scss';
import ContactForm from '../../common/contact-form/contact-form';

const ContactPage = () => {
  return (
    <div className={`contact-page-wrap`}>
      <Helmet>
        <title>Contactez-nous | Gîte écologique de Gaïa</title>
        <meta
          name="description"
          content="Contactez le Gîte Écologique de Gaïa à Rocheservière, Vendée. Accès facile via l'autoroute A83, à 15 minutes de la gare SNCF de Montaigu et 40 minutes de l’aéroport de Nantes. Appelez au 06 35 42 50 04 ou laissez-nous un message via notre formulaire de contact."
        />
        <meta
          name="keywords"
          content="contact Gîte Écologique de Gaïa, localisation gîte, adresse gîte, téléphone gîte, formulaire de contact, accès gîte, autoroute A83, gare SNCF Montaigu, aéroport Nantes, hébergement Vendée, vacances nature"
        />
      </Helmet>
      <Breadcrumb title={'Contactez-nous'} />
      <div className="section-wrapper">
        <div className="container">
          <div className="row access-section d-flex align-items-center">
            <div className="col-lg-4 mx-auto col-md-4 col-sm-12 mb-5">
              <div className="content">
                <h4 className="text-uppercase text-center">
                  Comment nous trouver
                </h4>
                <p className="text-center pt-5">
                  Accès facile via l'autoroute A83 (sortie Montaigu), à 15
                  minutes de la gare SNCF de Montaigu et 40 minutes de
                  l’aéroport de Nantes.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-4 col-sm-4 mx-auto">
              <div class="mapouter">
                <div class="gmap_canvas" style={{}}>
                  <iframe
                    class="gmap_iframe"
                    width="100%"
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                    src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Gite de gaia 3 La Taillée 85 620 Rocheservière&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="contact-icons"
        className="section-wrapper"
        style={{
          backgroundColor: '#cabe9f',
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-4 col-sm-4">
              <div className="box-icon">
                <div
                  className="icon"
                  style={{
                    color: '#3f4448',
                  }}
                >
                  <i className="opal-icon-phone"></i>
                </div>
                <div className="content">
                  <span
                    className="title text-uppercase"
                    style={{
                      color: '#3f4448',
                    }}
                  >
                    TÉLÉPHONE :
                  </span>
                  <p
                    className="description"
                    style={{
                      color: '#3f4448',
                    }}
                  >
                    06 35 42 50 04
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4">
              <div className="box-icon no-border">
                <div
                  className="icon"
                  style={{
                    color: '#3f4448',
                  }}
                >
                  <i className="opal-icon-point"></i>
                </div>
                <div className="content">
                  <span
                    className="title text-uppercase"
                    style={{
                      color: '#3f4448',
                    }}
                  >
                    ADRESSE :
                  </span>
                  <p
                    className="description"
                    style={{
                      color: '#3f4448',
                    }}
                  >
                    3 La Taillée
                    <br />
                    85 620 Rocheservière, France
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-wrapper">
        <ContactForm source="Page de contact" useFullName={false} />
      </div>
    </div>
  );
};

export default ContactPage;
