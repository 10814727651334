import React from 'react';
import Breadcrumb from '../../common/breadcrumb/breadcrumb';
import Helmet from 'react-helmet';
import Heading from '../../common/heading/heading';
import './decouvrir.scss';

const DecouvrirPage = () => {
  return (
    <div className="decouvrir-wrap">
      <Helmet>
        <title>À Découvrir | Gîte écologique de Gaïa</title>
        <meta
          name="description"
          content="Explorez Rocheservière et ses environs depuis le Gîte Écologique de Gaïa. Découvrez le parc éolien, faites du canoë-kayak sur la Boulogne, profitez des randonnées pédestres et cyclables. À proximité, visitez le Puy du Fou, Clisson, Nantes, les marais salants de Noirmoutier, et bien plus encore. Des activités pour toute la famille en Vendée."
        />
        <meta
          name="keywords"
          content="Rocheservière, Gîte Écologique de Gaïa, parc éolien, canoë-kayak, randonnées pédestres, randonnées cyclables, Puy du Fou, Clisson, Nantes, marais salants, Noirmoutier, activités familiales, tourisme Vendée, nature, patrimoine, culture, loisirs, vacances"
        />
      </Helmet>
      <Breadcrumb title="À Découvrir" />

      {/* Explorez Rocheservière et ses environs */}
      <div className="section-wrapper mt-5">
        <div className="container">
          <Heading>Explorez Rocheservière et ses environs</Heading>
          <div className="row align-items-center pt-5">
            <div className="col-lg-6">
              <img
                className="img-fluid"
                src="/assets/images/activites/eolien.jpg"
                alt="Parc Éolien"
              />
            </div>
            <div className="col-lg-6 content-right">
              <h4>Visite guidée du parc éolien</h4>
              <p>
                À seulement 5 minutes du Gîte de Gaïa, partez à la découverte du
                parc éolien grâce à un visio-guide pour une expérience
                immersive. Plongez au cœur des énergies renouvelables et de la
                technologie verte.
              </p>
            </div>
          </div>
          <div className="row flex-lg-row-reverse align-items-center mt-4">
            <div className="col-lg-6">
              <img
                className="img-fluid"
                src="/assets/images/activites/canoe.jpg"
                alt="Canoë sur la Boulogne"
              />
            </div>
            <div className="col-lg-6 content-left">
              <h4>Canoë et kayak sur la Boulogne</h4>
              <p>
                La base de canoë-kayak de Rocheservière vous accueille toute
                l'année pour une balade en toute quiétude sur la Boulogne,
                encadrée par un animateur Brevet d'État. Louez un canoë, un
                kayak ou une barque et laissez-vous porter au fil de l'eau.
              </p>
            </div>
          </div>
          <div className="row align-items-center mt-4">
            <div className="col-lg-6">
              <img
                className="img-fluid"
                src="/assets/images/activites/randonnee.jpg"
                alt="Randonnées pédestres et cyclables"
              />
            </div>
            <div className="col-lg-6 content-right">
              <h4>Randonnées pédestres et cyclables</h4>
              <p>
                Au départ du Gîte de Gaïa, partez à la découverte des paysages
                vendéens grâce aux nombreux circuits de randonnée pédestre et
                cyclable. Entre bocage, forêts et rivières, il y en a pour tous
                les goûts et tous les niveaux.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Dans un rayon d'une heure autour du Gîte */}
      <div className="section-wrapper mt-5">
        <div className="container">
          <Heading>Dans un rayon d'une heure autour du Gîte</Heading>
          <div className="row align-items-center pt-5">
            <div className="col-lg-6">
              <img
                className="img-fluid"
                src="/assets/images/activites/puy-du-fou.jpg"
                alt="Puy du Fou"
              />
            </div>
            <div className="col-lg-6 content-right">
              <h4>Patrimoine et culture</h4>
              <p>
                <ul>
                  <li>
                    Le Puy du Fou : un voyage spectaculaire à travers l'histoire
                  </li>
                  <li>
                    Le Logis de la Chabotterie, Clisson et son architecture
                    italienne, l'Historial de Vendée
                  </li>
                  <li>
                    Le Château de Tiffauges, demeure du légendaire Barbe-Bleue
                  </li>
                  <li>
                    Nantes : le Château des Ducs de Bretagne, le Jardin des
                    Plantes, les Machines de l'Île
                  </li>
                  <li>
                    Le Hellfest, festival de musique metal à Clisson, en juin
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div className="row flex-lg-row-reverse align-items-center mt-4">
            <div className="col-lg-6">
              <img
                className="img-fluid"
                src="/assets/images/activites/maulevrier.jpg"
                alt="Jardins et pagodes du Parc Oriental de Maulévrier"
              />
            </div>
            <div className="col-lg-6 content-left">
              <h4>Nature et grands espaces</h4>
              <p>
                <ul>
                  <li>
                    Le Parc Oriental de Maulévrier, plus grand jardin japonais
                    d'Europe
                  </li>
                  <li>
                    La Maison de la Rivière à St Georges de Montaigu : balades
                    en barque, musée, randonnées
                  </li>
                  <li>Les marais salants de Noirmoutier et la route du sel</li>
                  <li>
                    La Brière et ses chaumières, les bords de Loire à vélo
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div className="row align-items-center mt-4">
            <div className="col-lg-6">
              <img
                className="img-fluid"
                src="/assets/images/activites/cite-oiseaux.jpg"
                alt="randonneur en train de dessiner un paysage"
              />
            </div>
            <div className="col-lg-6 content-right">
              <h4>Art et créativité en pleine nature</h4>
              <p>
                <ul>
                  <li>
                    RANDO CROQUIS à St Philbert de Bouaine : un circuit de
                    randonnée jalonné de pupitres d'initiation au dessin
                  </li>
                  <li>
                    Le Jardin du Vent à Notre Dame de Monts : un espace poétique
                    et ludique dédié au vent
                  </li>
                  <li>
                    La Cité des Oiseaux aux Landes Genusson : observatoire
                    ornithologique et sentiers de découverte
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Où se Renseigner ? */}
      <div className="section-wrapper">
        <div className="container">
          <Heading>Où se Renseigner ?</Heading>
          <div className="row justify-content-center align-items-center pt-5">
            <div className="col-6 align-items-center">
              <p>
                N'hésitez pas à contacter l'Office de Tourisme Terres de
                Montaigu pour plus d'informations et pour organiser vos
                activités.
              </p>
              <h4>Coordonnées :</h4>
              <p>
                Office de Tourisme Terres de Montaigu
                <br />
                67, rue Georges Clemenceau
                <br />
                85600 Montaigu-Vendée
                <br />
                02 51 06 39 17
                <br />
              </p>
              <a
                href="https://www.terresdemontaigu.fr/"
                target="_blank"
                rel="noreferrer"
              >
                Site web de l'Office de Tourisme
              </a>
            </div>
            <div className="col-6 align-items-center">
              <h4>De septembre à juin :</h4>
              <p>
                du mardi au vendredi de 10h à 13h et de 14h30 à 18h30
                <br />
                le samedi de 9h30 à 13h et de 14h30 à 18h
              </p>
              <h4>En juillet et août :</h4>
              <p>
                du lundi au vendredi de 10h à 13h et de 14h30 à 18h30
                <br />
                le samedi de 9h30 à 13h et de 14h30 à 18h
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Section des Logos des Partenaires */}
      <div className="section-wrapper">
        <div className="row justify-content-center align-items-center">
          <div className="col-auto">
            <img
              className="img-fluid partner-logo"
              src="/assets/images/partenaires/le-voyage-a-nantes_logo.svg"
              alt="Le Voyage à Nantes"
              style={{ width: '150px', height: 'auto' }}
            />
          </div>
          <div className="col-auto">
            <img
              className="img-fluid partner-logo"
              src="/assets/images/partenaires/terres-de-maintaigu_logo.png"
              alt="Terres de Maintaigu"
            />
          </div>
          <div className="col-auto">
            <img
              className="img-fluid partner-logo"
              src="/assets/images/partenaires/hellfest_logo.png"
              alt="Hellfest"
            />
          </div>
          <div className="col-auto">
            <img
              className="img-fluid partner-logo"
              src="/assets/images/partenaires/puy-du-fou_logo.png"
              alt="Puy du Fou"
            />
          </div>
          <div className="col-auto">
            <img
              className="img-fluid partner-logo"
              src="/assets/images/partenaires/vendee-expansion_logo.png"
              alt="Vendée Expansion"
            />
          </div>
          <div className="col-auto">
            <img
              className="img-fluid partner-logo"
              src="/assets/images/partenaires/les-machines-de-l-ile_logo.svg"
              alt="Les Machines de l'île"
              style={{ width: '150px', height: 'auto' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DecouvrirPage;
