import React, { Component } from 'react';
import './footer.scss';
import Copyright from '../copyright/copyright';
import ContactForm from '../contact-form/contact-form';

class Footer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <footer className="site-footer">
        {/*Section #1*/}
        <div className="section-1 text-center">
          <div className="container">
            <h3 className="text-uppercase">DÉCOUVREZ</h3>
            <h2 className="text-uppercase">L'ESPRIT NATURE</h2>
            <h3 className="text-uppercase">AU CŒUR DE LA VENDÉE</h3>
          </div>
        </div>

        {/*Section 2*/}
        <div className="section-2">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12">
                <h6 className="heading-title">LOCALISATION DU GÎTE</h6>
                <p>
                  Gîte écologique de Gaïa. Un corps de ferme, une étable rénovée
                  en matériaux sains. Une ambiance chaleureuse et naturelle.
                </p>
                <div className="icon-list footer-address">
                  <ul>
                    <li>
                      <span className="icon-list-icon">ADRESSE:</span>
                      <span className="icon-list-text">
                        Gîte de Gaïa
                        <br />
                        3, La Taillée
                        <br />
                        85 620 Rocheservière, France
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <h6 className="heading-title">VOTRE HÔTE</h6>
                <div className="image-box-wrap">
                  <div className="image-box-img">
                    <img
                      src="/assets/imagewebp/theme/avatar_nathalie.png"
                      alt="Nathalie Soëls"
                      className={`img-fluid`}
                    />
                  </div>
                  <div className="image-box-content">
                    <h3 className="image-box-title">Nathalie Soëls</h3>
                    <span className="image-box-description">
                      PROPRIÉTAIRE DU GÎTE
                    </span>
                  </div>
                </div>
                <div className="icon-list">
                  <ul>
                    <li>
                      <span className="icon-list-icon">
                        <i className="fa fa-phone"></i>
                      </span>
                      <span className="icon-list-text">06 35 42 50 04</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-1 col-md-12"></div>
              <div className="col-lg-4 col-md-12">
                <h6 className="heading-title">CONTACTEZ-NOUS</h6>
                <ContactForm source="Footer" useFullName={true} />
              </div>
            </div>
          </div>
        </div>
        {/*Section 3*/}
        <div className="section-3">
          <div className="container text-center">
            <div className="row">
              <div className="col-sm-12" style={{ paddingBottom: '20px' }}>
                <a href="/mentions-legales">Mentions légales</a>
              </div>
              <div className="col-sm-12">
                <Copyright title="Gîte écologique de Gaïa" />
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
