import React, { Component } from 'react';
import './amenities.scss';
import Heading from '../../common/heading/heading';
import CarouselPage1 from '../../common/slider/slider1';

class AmenitiesSection extends Component {
  render() {
    return (
      // <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 col-md-12 col-sm-12">
          <CarouselPage1 />
        </div>
        <div className="col-lg-4 col-md-12 col-sm-12 content-right">
          <Heading>NATURE &amp; CONFORT</Heading>
          <p
            className="mb-5"
            style={{
              textAlign: 'justify',
            }}
          >
            Le Gîte de Gaïa est situé sur la commune de Rocheservière, en pleine
            campagne.
            <br />
            <br />
            Au centre d'un corps de ferme et d'un domaine de 5000 m2, le jardin
            du Gîte de Gaïa, de 2000 m2, est entouré de haies bocagères, d'une
            prairie fleurie, d'une aire de jeux pour enfants et d'un verger.
            Cette ancienne étable en pierre de 80 m2 a été rénovée entièrement
            avec des matériaux écologiques (enduits chaux/chanvre/lin, sol en
            terre cuite, isolation en laine de lin, peintures minérales...).
            <br />
            <br />
            Le confort des 2 chambres (capacité 4 personnes), la chaleur du
            poêle à granulés de lin et de bois, la qualité des équipements de ce
            gîte, feront de votre séjour un réel moment de détente.
            <br />
            <br />
            En couple, entre amis ou en famille, le Gîte de Gaïa vous permettra
            de vivre de belles vacances.
            <br />
            <br />
            Au plaisir de vous accueillir !
            <br />
            Nathalie
          </p>
          {/* <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="icon-list">
                <div className="icon-list-wrap">
                  <ul>
                    <li className="icon-list-items">
                      <span className="icon-list-icon">
                        <i className="fa fa-angle-right"></i>
                      </span>
                      <span className="icon-list-text">Business Services</span>
                    </li>
                    <li className="icon-list-items">
                      <span className="icon-list-icon">
                        <i className="fa fa-angle-right"></i>
                      </span>
                      <span className="icon-list-text">Courtesy Newspaper</span>
                    </li>
                    <li className="icon-list-items">
                      <span className="icon-list-icon">
                        <i className="fa fa-angle-right"></i>
                      </span>
                      <span className="icon-list-text">Fitness Facility</span>
                    </li>
                    <li className="icon-list-items">
                      <span className="icon-list-icon">
                        <i className="fa fa-angle-right"></i>
                      </span>
                      <span className="icon-list-text">Meeting & Event</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="icon-list">
                <div className="icon-list-wrap">
                  <ul>
                    <li className="icon-list-items">
                      <span className="icon-list-icon">
                        <i className="fa fa-angle-right"></i>
                      </span>
                      <span className="icon-list-text">Swimming Pool</span>
                    </li>
                    <li className="icon-list-items">
                      <span className="icon-list-icon">
                        <i className="fa fa-angle-right"></i>
                      </span>
                      <span className="icon-list-text">Restaurant</span>
                    </li>
                    <li className="icon-list-items">
                      <span className="icon-list-icon">
                        <i className="fa fa-angle-right"></i>
                      </span>
                      <span className="icon-list-text">Community Center</span>
                    </li>
                    <li className="icon-list-items">
                      <span className="icon-list-icon">
                        <i className="fa fa-angle-right"></i>
                      </span>
                      <span className="icon-list-text">Spa Facility</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      // </div>
    );
  }
}

export default AmenitiesSection;
