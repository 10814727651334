import React, { useState, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import axios from 'axios';
import { toast } from 'react-toastify';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import './contact-form.scss';

const API_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://1wfsp8uh0d.execute-api.eu-west-3.amazonaws.com/production/api/send-email'
    : 'http://localhost:3000/send-email';

const commonSchema = {
  email: z
    .string()
    .nonempty('Ce champ est obligatoire')
    .email("Format d'email invalide"),
  phone: z
    .string()
    .optional()
    .refine(
      (value) =>
        !value ||
        /^[+]?(\d{1,4})?[-.\s]?(\d{1,4})?[-.\s]?(\d{1,4})[-.\s]?(\d{1,9})$/.test(
          value
        ),
      {
        message: 'Format de téléphone invalide',
      }
    ),
  message: z.string().nonempty('Ce champ est obligatoire'),
};

const getSchema = (useFullName) => {
  if (useFullName) {
    return z.object({
      fullName: z.string().nonempty('Ce champ est obligatoire'),
      ...commonSchema,
    });
  } else {
    return z.object({
      firstName: z.string().optional(),
      lastName: z.string().nonempty('Ce champ est obligatoire'),
      ...commonSchema,
    });
  }
};

const ContactForm = ({ source, useFullName }) => {
  const [token, setToken] = useState(null);
  const [clientIp, setClientIp] = useState(null);
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(getSchema(useFullName)),
  });

  const onVerify = useCallback((token) => {
    setToken(token);
  }, []);

  useEffect(() => {
    // Fetch the client's IP address
    const fetchIp = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setClientIp(response.data.ip);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    fetchIp();
  }, []);

  const onSubmit = async (data) => {
    if (!token) {
      toast.error('Veuillez vérifier le reCAPTCHA');
      return;
    }

    const timestamp = new Date().toISOString();
    const formData = { ...data, token, clientIp, timestamp, source };

    try {
      await axios.post(API_URL, formData);
      toast.success('Message envoyé avec succès!');
      setRefreshReCaptcha((prev) => !prev); // Refresh reCAPTCHA
    } catch (error) {
      console.error('Error sending email:', error); // Debugging log
      toast.error("Une erreur s'est produite, veuillez réessayer plus tard.");
    }
  };

  return (
    <div>
      <GoogleReCaptcha
        onVerify={onVerify}
        refreshReCaptcha={refreshReCaptcha}
      />

      {useFullName ? (
        <form className="form-group-v1" onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="form-group">
                <input
                  {...register('fullName')}
                  type="text"
                  placeholder="Votre nom *"
                  className="form-control"
                />
                {errors.fullName && (
                  <small className="form-text text-danger">
                    {errors.fullName.message}
                  </small>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="form-group">
                <input
                  {...register('phone')}
                  type="text"
                  placeholder="Votre téléphone *"
                  className="form-control"
                />
                {errors.phone && (
                  <small className="form-text text-danger">
                    {errors.phone.message}
                  </small>
                )}
              </div>
            </div>
          </div>

          <div className="form-group">
            <input
              {...register('email')}
              type="email"
              placeholder="Votre email *"
              className="form-control"
            />
            {errors.email && (
              <small className="form-text text-danger">
                {errors.email.message}
              </small>
            )}
          </div>

          <div className="form-group">
            <textarea
              {...register('message')}
              className="form-control"
              placeholder="Votre message *"
            />
            {errors.message && (
              <small className="form-text text-danger">
                {errors.message.message}
              </small>
            )}
          </div>

          <button type="submit" className="btn btn-primary mt-4 btn-block">
            Envoyer
          </button>
        </form>
      ) : (
        <form
          className="section-wrapper form-contact mb-5"
          onSubmit={handleSubmit(onSubmit)}
        >
          <h3 className="text-uppercase text-center">Laissez-moi un message</h3>
          <div className="mt-4 row">
            <div className="col-6 mb-3">
              <input
                {...register('firstName')}
                type="text"
                className="form-control"
                placeholder="Votre prénom"
              />
              {errors.firstName && (
                <small className="form-text text-danger">
                  {errors.firstName.message}
                </small>
              )}
            </div>
            <div className="col-6 mb-3">
              <input
                {...register('lastName')}
                type="text"
                className="form-control"
                placeholder="Votre nom de famille *"
              />
              {errors.lastName && (
                <small className="form-text text-danger">
                  {errors.lastName.message}
                </small>
              )}
            </div>
            <div className="col-6 mb-3">
              <input
                {...register('email')}
                type="email"
                className="form-control"
                placeholder="Votre email *"
              />
              {errors.email && (
                <small className="form-text text-danger">
                  {errors.email.message}
                </small>
              )}
            </div>
            <div className="col-6 mb-3">
              <input
                {...register('phone')}
                type="tel"
                className="form-control"
                placeholder="Votre téléphone"
              />
              {errors.phone && (
                <small className="form-text text-danger">
                  {errors.phone.message}
                </small>
              )}
            </div>
            <div className="col-12 mb-3">
              <textarea
                {...register('message')}
                className="form-control"
                placeholder="Votre message *"
                rows="3"
              />
              {errors.message && (
                <small className="form-text text-danger">
                  {errors.message.message}
                </small>
              )}
            </div>
            <div className="col-12">
              <button type="submit" className="btn btn-primary w-100">
                ENVOYER LE MESSAGE <i className="opal-icon-arrow"></i>
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default ContactForm;
