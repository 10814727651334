import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Custom Components
import Header from './components/common/headers/header';
import Footer from './components/common/footers/footer';
import PushMenu from 'react-push-menu';
import LinkComponent from './lib/menuPush/LinkComponent';
import './lib/menuPush/component.scss';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };

    this.load();
  }

  load = () => {
    fetch('/api/menu.json')
      .then((response) => response.json())
      .then((res) => {
        this.menu = res;
        this.setState({ isLoading: false });
        document.querySelector('.loader-wrapper').style = 'display: none';
      });
  };

  render() {
    return (
      !this.state.isLoading && (
        <div className="opal-wrapper">
          <PushMenu
            backIcon={<i className="fa fa fa-arrow-left"></i>}
            expanderComponent={() => {
              return <i className="fa fa-chevron-right"></i>;
            }}
            autoHide={true}
            nodes={{ children: this.menu }}
            type={'cover'}
            linkComponent={LinkComponent}
            propMap={{ url: 'link' }}
          >
            <div id="page" className="site">
              <Header
                logoName={'gaia-horizontal-1300x320-dark.svg'}
                menu={this.menu}
              />
              {this.props.children}
              <Footer logoName={'gaia-horizontal-1300x320-dark.svg'} />
            </div>
          </PushMenu>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </div>
      )
    );
  }
}

export default App;
