import React from 'react';
import Breadcrumb from '../../common/breadcrumb/breadcrumb';
import Helmet from 'react-helmet';
import ApartmentsPlansSection from '../../sections/ourvalues/ourvalues';
import GallerrySection from '../../sections/gallerry/gallerry';
import './equipements.scss';

const EquipementsPage = () => {
  return (
    <div className={`equipements-wrap`}>
      <Helmet>
        <title>Équipements | Gîte écologique de Gaïa</title>
        <meta
          name="description"
          content="Découvrez les équipements du Gîte Écologique de Gaïa. Profitez d'une terrasse ensoleillée, d'un jardin de 2000 m2 avec aire de jeux, d'une cuisine moderne, d'un salon convivial avec Wi-Fi gratuit, et de chambres confortables. Parfait pour des vacances en famille ou entre amis en Vendée."
        />
        <meta
          name="keywords"
          content="Gîte écologique, équipements gîte, terrasse, jardin, cuisine équipée, salon, Wi-Fi gratuit, chambres confortables, vacances Vendée, hébergement écologique, gîte nature, gîte familial"
        />
      </Helmet>
      <Breadcrumb title={'Équipements'} />
      <div className="section-wrapper">
        <div className="container mt-5 mb-5">
          <ApartmentsPlansSection />
        </div>
      </div>
      <div className="section-wrapper">
        <div className="gallerry-wrap">
          <GallerrySection flexRow={true} />
        </div>
      </div>
    </div>
  );
};

export default EquipementsPage;
