import React from 'react';
import Breadcrumb from '../../common/breadcrumb/breadcrumb';
import Helmet from 'react-helmet';
import './legal-notice.scss';

const LegalNoticePage = () => {
  return (
    <div className="the-building-wrap mb-5">
      <Helmet>
        <title>Mentions Légales | Le Gîte écologique de Gaïa</title>
        <meta
          name="description"
          content="Consultez les mentions légales du Gîte Écologique de Gaïa. Informations sur l'éditeur, l'hébergeur, et les conditions d'utilisation de notre site."
        />
      </Helmet>
      <Breadcrumb title={'Mentions Légales'} />
      <div className="container mt-5">
        <div className="text-description mt-4" style={{ textAlign: 'justify' }}>
          <h4 className="mt-5">Éditeur du site</h4>
          <p>
            Le site web{' '}
            <a href="https://gite-de-gaia.com/">https://gite-de-gaia.com/</a>{' '}
            est édité par :
            <br />
            <br />
            SOELS NATHALIE
            <br />
            Entrepreneur individuel
            <br />
            SIREN : 525 367 629
            <br />
            SIRET (siège) : 525 367 629 00017
            <br />
            Numéro de TVA : FR48525367629
            <br />
            Inscrit au RNE le 01/10/2010
            <br />
            <br />
            Adresse :
            <br />
            Gîte de Gaïa
            <br />
            3, La Taillée
            <br />
            85620 Rocheservière, France
          </p>

          <h4 className="mt-5">
            Directeur de la publication et Délégué à la Protection des Données
            (DPO)
          </h4>
          <p>
            Nathalie SOËLS
            <br />
            Email :{' '}
            <a href="mailto:nathalie.soels@gmail.com">
              nathalie.soels@gmail.com
            </a>
            <br />
            Téléphone : 06 35 42 50 04
          </p>

          <h4 className="mt-5">Hébergement</h4>
          <p>Le site est hébergé par Amazon Web Services (AWS).</p>

          <h4 className="mt-5">Conception et maintenance</h4>
          <p>
            Le site web a été conçu et est maintenu par{' '}
            <a href="https://vedus.io">Vedus</a>.
          </p>

          <h4 className="mt-5">Propriété intellectuelle</h4>
          <p>
            Tous les contenus présents sur le site{' '}
            <a href="https://gite-de-gaia.com/">https://gite-de-gaia.com/</a>{' '}
            sont couverts par le droit d'auteur. Toute reprise est dès lors
            conditionnée à l'accord de l'auteur en vertu de l'article L.122-4 du
            Code de la Propriété Intellectuelle.
          </p>

          <h4 className="mt-5">Données personnelles</h4>
          <p>
            Les informations recueillies sont utilisées uniquement dans le cadre
            légal prévu en France pour le respect de la vie privée. Conformément
            au Règlement (UE) 2016/679 relatif à la protection des données à
            caractère personnel, vous pouvez exercer votre droit d'accès aux
            données vous concernant et les faire rectifier en contactant :
            <br />
            Nathalie SOELS, par email à{' '}
            <a href="mailto:nathalie.soels@gmail.com">
              nathalie.soels@gmail.com
            </a>
            .
          </p>

          <h4 className="mt-5">Loi applicable et juridiction compétente</h4>
          <p>
            Les présentes mentions légales sont régies par la loi française. En
            cas de litige et à défaut d'accord amiable, le litige sera porté
            devant les tribunaux français conformément aux règles de compétence
            en vigueur.
          </p>
        </div>
      </div>
    </div>
  );
};

export default LegalNoticePage;
