import React, { Component } from 'react';
import './ourservices.scss';
import Heading from '../../common/heading/heading';

class OurServicesSection extends Component {
  render() {
    return (
      <div className="ourservices">
        <div className="container">
          <Heading>Nos services</Heading>
          <div className="row">
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                      <i class="fa-solid fa-car"></i>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                      Parking Privé & Sécurisé
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                      Profitez d'un vaste espace de stationnement avec un accès
                      sécurisé, pour une tranquillité d'esprit pendant votre
                      séjour.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                      <i class="fa-solid fa-tree"></i>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                      Aire de Jeux
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                      Les enfants ont de l'espace pour courir et jouer en toute
                      tranquillité avec les équipements mis à disposition :
                      portique de balançoires, ballon, boules de pétanque,
                      raquette de badminton etc...
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                      <i class="fa-solid fa-kitchen-set"></i>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                      Terrasse Privée & Barbecue
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                      Savourez des repas en plein air sur votre terrasse privée
                      équipée d'un barbecue, idéale pour des soirées relaxantes
                      sous les étoiles.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                      <i class="fa-solid fa-wifi"></i>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                      Wi-Fi Gratuit & Divertissements
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                      Restez connecté et divertissez-vous avec le Wi-Fi, Wi-Fi
                      gratuit, la télévision, les livres de la bibliothèque du
                      gîte et les jeux de société.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="featured-box style-2 ">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                      <i class="fa-solid fa-leaf"></i>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                      Vivre Écologique
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                      Vivez confortablement tout en respectant l'environnement,
                      grâce à notre chauffage au poêle à granulés granulés (lin
                      et bois), aux produits d'entretien Bio mis à disposition
                      et à la gestion de l'eau raisonnée.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                      <i class="fa-solid fa-baby-carriage"></i>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                      Équipements Familiaux
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                      Voyagez léger grâce à nos équipements adaptés aux bébés,
                      incluant une chaise haute, une poussette canne, une
                      baignoire bébé, un lit parapluie, des jeux et livres pour
                      enfant.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                      <i class="fa-solid fa-utensils"></i>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                      Cuisine Gourmet
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                      Préparez de délicieux repas dans une cuisine entièrement
                      équipée, avec des appareils modernes et des produits de
                      base indispensables (café, thé, tisane, sucre, huile,
                      vinaigre, sel..).
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                      <i class="fa-solid fa-bed"></i>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                      Couchages Confortables
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                      Reposez-vous dans nos chambres douillettes avec literie de
                      qualité, pouvant accueillir jusqu'à 4 personnes pour une
                      nuit de sommeil réparatrice.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                      <i class="fa-solid fa-person-hiking"></i>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                      Activités Nature & Découvertes
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                      Explorez les trésors de Rocheservière : chemin de
                      randonnée, sortie en barque ou kayak, balade VTT, visite
                      du site St Sauveur...
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OurServicesSection;
