import React, { Component } from 'react';
import Heading from '../../common/heading/heading';

class OverviewSection extends Component {
  render() {
    return (
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 col-sm-12 content-left">
            <Heading>LE GÎTE ÉCOLOGIQUE DE GAÏA</Heading>
            <p
              className="text-black"
              style={{
                textAlign: 'justify',
              }}
            >
              Le Gîte de Gaïa, 3 étoiles (classement préféctoral), est une
              ancienne étable en pierre, chaleureuse et confortable, rénovée
              avec des matériaux naturels et locaux.
            </p>
            <p
              style={{
                textAlign: 'justify',
              }}
            >
              J’ai plaisir à vous accueillir, dans un environnement calme et
              verdoyant. Venez découvrir les activités phares de la Vendée (le
              Puy du Fou, l'océan, randonnées en forêt, au bord de la rivière ou
              dans les marais salants…).
            </p>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <img
              className="img-fluid"
              src="/assets/images/slider/facade-cours-1350x900.jpg"
              alt="Gîte de Gaïa - Façade"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default OverviewSection;
