import React, { Component } from 'react';
import Breadcrumb from './common/breadcrumb/breadcrumb';
import Helmet from 'react-helmet';

class PageNotFound extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Page non trouvée | Gîte écologique de Gaïa</title>
          <meta
            name="description"
            content="Page non trouvée. La page que vous recherchez n'existe pas ou a été déplacée. Retournez à la page d'accueil du Gîte Écologique de Gaïa."
          />
        </Helmet>
        <Breadcrumb title={'Page 404'} />
        <section className="p-0">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="error-section">
                  <div className="error-bkg">
                    <img
                      style={{ marginTop: '10px' }}
                      src={`${process.env.PUBLIC_URL}/assets/imagewebp/theme/404.webp`}
                      className="img-fluid"
                      alt="404"
                    />
                  </div>
                  <div className="error-404-title">
                    <h1 className="p-0 m-0">404</h1>
                    <div className="error-404-subtitle">
                      <h2 className="sub-h2-1 p-0 m-0">OOPS...</h2>
                      <h2 className="sub-h2-2 p-0 m-0">Page non trouvée !</h2>
                    </div>
                  </div>
                  <div className="error-text">
                    Oops! La page que vous recherchez n'a pas été trouvée. Il se
                    peut que vous ayez mal saisi l'adresse ou que la page ait
                    été déplacée.
                  </div>
                  <div className="back-home">
                    <a href="" className="btn btn-primary return-homepage">
                      Retour à l'accueil
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default PageNotFound;
