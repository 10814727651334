import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
// Import custom components
import SliderHome from './common/slider/slider';
import OverviewSection from './sections/overview/overview';
import OurServicesSection from './sections/ourservices/ourservices';
import CallToActionSection from './sections/caltoaction/caltoaction';
import AvailabilitySection from './sections/availability/availability';
import AmenitiesSection from './sections/amenities/amenities';
import ApartmentsPlansSection from './sections/ourvalues/ourvalues';
class Home extends Component {
  render() {
    return (
      <div className={`home-page-wrap`}>
        <Helmet>
          <title>Gîte écologique de Gaïa</title>
          <meta
            name="description"
            content="Découvrez le Gîte Écologique de Gaïa, une ancienne étable en pierre rénovée avec des matériaux naturels. Situé à Rocheservière, ce gîte 3 étoiles offre un cadre paisible avec un jardin de 2000 m2, des équipements écologiques, et des activités pour toute la famille. Parfait pour des vacances en Vendée, près du Puy du Fou et de l'océan. Contactez Nathalie Soëls, votre hôte, pour une expérience authentique au cœur de la Vendée."
          />
          <meta
            name="keywords"
            content="Gîte écologique, Gîte de Gaïa, Rocheservière, Vendée, vacances nature, éco-responsable, Puy du Fou, hébergement écologique, location vacances, tourisme vert, confort, jardin, activités familiales, Wi-Fi gratuit, terrasse privée, parking sécurisé, poêle à granulés, matériaux écologiques, Nathalie Soëls, gîte familial"
          />

          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css"
            integrity="sha512-DTOQO9RWCH3ppGqcWaEA1BIZOC6xxalwEsw9c2QQeAIftl+Vegovlnee1c9QX4TctnWMn13TZye+giMm8e2LwA=="
            crossorigin="anonymous"
            referrerpolicy="no-referrer"
          />
        </Helmet>
        <div className="section-wrapper pt-0 pb-0">
          <SliderHome />
        </div>
        <div className="section-wrapper">
          <OverviewSection />
        </div>
        <div className="section-wrapper pl-6 pr-6">
          <AmenitiesSection />
        </div>
        <div className="section-wrapper">
          <OurServicesSection />
        </div>
        <div className="section-wrapper">
          <ApartmentsPlansSection />
        </div>
        <div className="section-wrapper pb-0">
          <CallToActionSection />
        </div>
        <div className="section-wrapper">
          <AvailabilitySection />
        </div>
      </div>
    );
  }
}

export default Home;
