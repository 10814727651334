import React, { Component } from 'react';
import Breadcrumb from '../../common/breadcrumb/breadcrumb';
import Helmet from 'react-helmet';
import 'react-image-lightbox/style.css';
import './tarification.scss';
import LoadingBlock from '../../common/loading/loading';
import _ from 'lodash';

class TheBuildingPage extends Component {
  data = [];
  keys = [];
  state = {
    isLoading: true,
  };

  constructor(props) {
    super(props);
    this.load();
  }

  load = () => {
    fetch('/api/availability.json')
      .then((response) => response.json())
      .then((res) => {
        this.data = res;
        this.setState({ isLoading: false });
      });
  };

  getHeading = () => {
    this.keys = _.keys(this.data[0]);
    return this.keys;
  };

  render() {
    return (
      <div className={`the-building-wrap mb-5`}>
        <Helmet>
          <title>Tarifs et réservation | Le Gîte écologique de Gaïa</title>
          <meta
            name="description"
            content="Découvrez les tarifs et réservez votre séjour au Gîte Écologique de Gaïa. Profitez d'un hébergement confortable et écologique en Vendée, avec des tarifs incluant eau, électricité, et granulés pour le poêle. Réservation facile par téléphone ou formulaire en ligne."
          />
          <meta
            name="keywords"
            content="tarifs Gîte Écologique de Gaïa, réservation gîte, hébergement écologique Vendée, vacances nature, tarifs location, paiement chèque, chèque vacances ANCV, taxe de séjour, réservation en ligne, séjour confortable, séjour écologique, vacances en famille"
          />
        </Helmet>
        <Breadcrumb title={'Tarifs et Réservation'} />
        <div className="container mt-5">
          <h3 className="text-center">Tarifs</h3>
          <p className="text-center mt-4 text-description">
            Découvrez nos tarifs pour des séjours inoubliables au Gîte
            Écologique de Gaïa. Profitez d'un hébergement confortable et
            écologique au cœur de la Vendée.
          </p>
          {!this.state.isLoading ? (
            <>
              <div className="block-table table-responsive">
                <table className="table">
                  <thead>
                    <tr className="property-variation-item text-uppercase">
                      {this.getHeading().map((heading, index) => (
                        <th key={index}>{heading}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {this.data.map((item, index) => (
                      <tr className="property-variation-item" key={index}>
                        {this.keys.map((key, i) => (
                          <td key={i}>{item[key]}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div class="text-description text-center">
                <p>
                  Tous nos tarifs incluent l'eau, l'électricité et les granulés
                  pour le poêle.
                </p>
              </div>
              <div className="container mt-5">
                <div className="row justify-content-center">
                  <div className="col-11 col-sm-8 col-md-5 col-lg-4 align-items-center text-center p-5">
                    <p>Moyens de paiement acceptés :</p>
                    <br />
                    <ul className="list-unstyled">
                      <li>Paiement par chèque</li>
                      <li>Espèce</li>
                      <li>Chèque vacances ANCV</li>
                    </ul>
                  </div>
                  <div className="vertical-divider"></div>
                  <div className="col-11 col-sm-8 col-md-5 col-lg-4 d-flex justify-content-center align-items-center p-5">
                    <a href="https://www.ancv.com/">
                      <img
                        src="/assets/images/logos/ancv-logo.png"
                        alt="Logo ANCV"
                        style={{ height: '100px' }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <LoadingBlock />
          )}
        </div>
        <div className="container mt-5">
          <div className="row justify-content-center">
            <div className="col-11 col-sm-8 col-md-6 col-lg-4 card m-2 m-md-2 m-lg-2 p-5">
              <h3 className="card-title text-center">Taxe de séjour</h3>
              <p
                className="card-text"
                style={{ marginTop: '20px', textAlign: 'justify' }}
              >
                Une taxe de séjour de 1,40 € par nuit et par personne (à partir
                de 13 ans) est à régler sur place à la fin de votre séjour.
                Cette taxe, fixée par la municipalité, contribue aux dépenses
                liées à l'accueil des touristes et à l'amélioration des
                infrastructures locales.
                <br />
                <br />
                <span style={{ fontStyle: 'italic' }}>
                  La taxe de séjour est gratuite pour les enfants de moins de 13
                  ans.
                </span>
              </p>
            </div>
            <div className="col-11 col-sm-8 col-md-6 col-lg-4 card m-2 m-md-2 m-lg-2 p-5">
              <h3 className="card-title text-center">Réservations</h3>
              <p
                className="card-text"
                style={{ marginTop: '20px', textAlign: 'justify' }}
              >
                Pour réserver votre séjour au Gîte Écologique de Gaïa, vous
                pouvez nous contacter par téléphone ou via le formulaire de
                contact ci-dessous :
                <br />
                <br />
                Téléphone : 06 35 42 50 04
                <br />
                Formulaire de contact :{' '}
                <a href="/contact" style={{ color: '#cabe9f' }}>
                  Cliquez ici
                </a>
                <br />
                <br />
                Lors de votre réservation, veuillez nous indiquer :
                <br />
              </p>
              <ul>
                <li>Vos dates de séjour</li>
                <li>Le nombre de personnes (adultes et enfants)</li>
                <li>Vos coordonnées (nom, adresse, téléphone, email)</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div class="col-8 text-description text-center">
            <p>
              Pour garantir la propreté et la tranquillité des lieux, nous vous
              informons que les animaux de compagnie ne sont pas autorisés au
              gîte. De plus, il est important de noter que le logement est
              entièrement non-fumeur.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default TheBuildingPage;
