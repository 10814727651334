import React, { useEffect, useRef, useState } from 'react';
import IframeResizer from 'iframe-resizer-react';
import Heading from '../../common/heading/heading';
import './booking.scss';
import ContactForm from '../contact-form/contact-form';

const BookingOpenPro = ({ source }) => {
  const ref = useRef(null);
  const [showButton, setShowButton] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === ref.current) {
          console.log(
            'Current height of #open-pro:',
            entry.target.offsetHeight
          );
          // Afficher le bouton si la hauteur dépasse 600px
          if (entry.target.offsetHeight > 600) {
            setShowButton(true);
            setShowMessage(true);
          } else {
            setShowButton(false);
            setShowMessage(false);
          }
        }
      }
    });

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div className="section-wrapper pb-0 pt-0" style={{ minWidth: '350px' }}>
      <div id="open-pro" ref={ref} className="container">
        <Heading>RÉSERVATION</Heading>
        <IframeResizer
          src="/widget.html"
          style={{ width: '1px', minWidth: '100%', border: 'none' }}
          checkOrigin={false}
          title="Booking Widget"
        />
        {showMessage && (
          <div className="section-wrapper">
            <div className="row justify-content-center">
              <p
                className="col-8 align-items-center"
                style={{ textAlign: 'justify' }}
              >
                Pour réserver, vous pouvez me contacter directement par
                téléphone au 06 35 42 50 04 ou en passant par le formulaire de
                réservation en cliquant sur le bouton ci-dessous.
                <br />
                <br />
                Lors de votre réservation, veuillez me préciser :
                <ul>
                  <li>Vos dates de séjour</li>
                  <li>Le nombre de personnes (adultes et enfants)</li>
                  <li>Vos coordonnées (nom, adresse, téléphone, email)</li>
                </ul>
                <br />
                Vous trouverez plus d'informations sur les tarifs et les
                conditions de réservation en{' '}
                <a href="/tarifs" style={{ color: '#cabe9f' }}>
                  cliquant ici
                </a>
                .
              </p>
            </div>
          </div>
        )}
        {showButton && !showForm && (
          <div className="align-items-center d-flex justify-content-center mt-2">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                setShowForm(true);
                setShowButton(false);
              }}
            >
              Réserver
            </button>
          </div>
        )}
        {showForm && <ContactForm source={source} useFullName={false} />}
      </div>
    </div>
  );
};

export default BookingOpenPro;
